import AmazonService from '../../services/amazon.service'

export const state = {
    categories: [],
    products: [],
    orders: [],
    selectedOrder: {},
    selectedProduct: {},
    cartProducts: [],
    selectedCartProduct: {},
};

export const mutations = {
    SET_CATEGORIES(state, value) {
        state.categories = value
    },

    ADD_PRODUCT(state, value) {
        state.products.unshift(value)
    },

    UPDATE_PRODUCT(state, value) {
        let index = state.products.findIndex(product => product.id == value.id)
        state.products[index] = value
    },

    SET_PRODUCTS(state, value) {
        state.products = value
    },

    SET_ONE_PRODUCT(state, value) {
        state.selectedProduct = value
    },

    DELETE_PRODUCT(state, value) {
        let index = state.products.findIndex((res) => res.id == value)
        state.products.splice(index, 1)
    },

    /**************************** START - CART FUNCTIONS **********************************/

    ADD_CART_PRODUCT(state, value) {
        state.cartProducts.push(value)
    },

    UPDATE_CART_PRODUCT(state, value) {
        let index = state.cartProducts.findIndex(product => product.id == value.id)
        state.cartProducts[index] = value
    },

    FIND_ALL_CART_PRODUCTS(state, value) {
        state.cartProducts = value
    },

    FIND_ONE_CART_PRODUCT(state, value) {
        state.selectedCartProduct = value
    },

    DELETE_CART_PRODUCT(state, value) {
        let index = state.cartProducts.findIndex((res) => res.id == value)
        state.cartProducts.splice(index, 1)
    },

    /**************************** END - CART FUNCTIONS **********************************/

    FIND_ALL_ORDERS(state, value) {
        state.orders = value
    },

    FIND_ONE_ORDER(state, value) {
        state.selectedOrder = value
    },
};

export const actions = {

    async findCategories({ commit }) {
        try {
            let response = await AmazonService.findCategories()
            commit('SET_CATEGORIES', response)
            return response
        }
        catch(error) {
            console.log('find all categories action', error)
            throw error
        }
    },
    
    async createProduct({commit}, payload) {
        try {
            let response = await AmazonService.createProduct(payload)
            commit('ADD_PRODUCT', response[0])
            return response
        }
        catch(error) {
            //console.log('create product action', error)
        }
    },
    
    async updateProduct({commit}, payload) {
        try {
            let response = await AmazonService.updateProduct(payload)
            commit('UPDATE_PRODUCT', response[0])
            return response
        }
        catch(error) {
            //console.log('update product action', error)
        }
    },

    async findAllProducts({ commit }) {
        try {
            let response = await AmazonService.findAllProducts()
            commit('SET_PRODUCTS', response)
            return response
        }
        catch(error) {
            console.log('find all products action', error)
            throw error
        }
    },

    async findOneProduct({ commit }, id) {
        try {
            let response = await AmazonService.findOneProduct(id)
            commit('SET_ONE_PRODUCT', response[0])
            return response
        }
        catch(error) {
            //console.log('find one product action', error)
        }
    },

    async deleteProduct({commit}, id) {
        try {
            let response = await AmazonService.deleteProduct(id)
            commit('DELETE_PRODUCT', id)
            return response
        }
        catch(error) {
            //console.log('delete product action', error)
        }
    },

    /**************** START - CART FUNCTIONS *********************/

    async addCartProduct({commit}, payload) {
        try {
            let response = await AmazonService.addCartProduct(payload)
            commit('ADD_CART_PRODUCT', response[0])
            return response
        }
        catch(error) {
            //console.log('add to cart action', error)
        }
    },
    
    async updateCartProduct({commit}, payload) {
        try {
            let response = await AmazonService.updateCartProduct(payload)
            commit('UPDATE_CART_PRODUCT', response[0])
            return response
        }
        catch(error) {
            //console.log('update cart action', error)
        }
    },

    async findAllCartProducts({ commit }, userId) {
        try {
            let response = await AmazonService.findAllCartProducts(userId)
            commit('FIND_ALL_CART_PRODUCTS', response)
            return response
        }
        catch(error) {
            console.log('find cart products action', error)
            throw error
        }
    },

    async findOneCartProduct({ commit }, productId) {
        try {
            let response = await AmazonService.findOneCartProduct(productId)
            commit('FIND_ONE_CART_PRODUCT', response[0])
            return response
        }
        catch(error) {
            //console.log('find one cart product action', error)
        }
    },

    async deleteCartProduct({commit}, productId) {
        try {
            let response = await AmazonService.deleteCartProduct(productId)
            commit('DELETE_CART_PRODUCT', productId)
            return response
        }
        catch(error) {
            //console.log('delete from cart action', error)
        }
    },

    /**************** START - CART FUNCTIONS *********************/

    async findAllOrders({ commit }) {
        try {
            let response = await AmazonService.findAllOrders()
            commit('FIND_ALL_ORDERS', response)
            return response
        }
        catch(error) {
            console.log('find all orders action', error)
            throw error
        }
    },

    async findOneOrder({ commit }, id) {
        try {
            let response = await AmazonService.findOneOrder(id)
            commit('FIND_ONE_ORDER', response[0])
            return response
        }
        catch(error) {
            //console.log('find one order action', error)
        }
    },
};

export const getters = {
    
};
