import http from "../common/http";

class ProductVideoService {
  createProductVideo(data) {
    return http.request('post', '/video/create', data);
  }
  
  updateProductVideo(data) {
    return http.request('put', '/video/update', data);
  }

  findProductVideosByBusinessId(businessId, userId) {
    return http.request('get', `/video/business/find-all?businessId=${businessId}&userId=${userId}`);
  }

  findAllProductVideos() {
    return http.request('get', `/video/find-all`);
  }

  findOneProductVideo(productId, productVideoId) {
    return http.request('get', `/video/find-one?productId=${productId}&productVideoId=${productVideoId}`);
  }

  findVideoByRef(ref) {
    return http.request('get', `/video/find-ref?ref=${ref}`);
  }

  deleteProductVideo(productId, productVideoId) {
    return http.request('delete', `/video/delete?productId=${productId}&productVideoId=${productVideoId}`);
  }

  generateQRCodeImage(data) {
    return http.request('post', '/video/qrcode/generate', data);
  }

  downloadProductVideo(data) {
    return http.request('post', '/video/download', data);
  }

  deleteVideoFile(data) {
    return http.request('post', '/video/local/delete', data);
  }

  likeVideo(data) {
    return http.request('post', '/video/like', data);
  }

  createVideoComment(data) {
    return http.request('post', '/video/comment/create', data);
  }

  updateVideoComment(data) {
    return http.request('put', `/video/comment/update`, data);
  }

  findAllVideoComments(videoId) {
    return http.request('get', `/video/comment/find-all?videoId=${videoId}`);
  }

  deleteVideoComment(commentId, isReply) {
    return http.request('delete', `/video/comment/delete?commentId=${commentId}&isReply=${isReply}`);
  }
}

export default new ProductVideoService();
