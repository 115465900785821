import StoreService from '../../services/store.service'

export const state = {
    portfolios: [],
    selectedPortfolio: {},
    portfolioProducts: [],
    selectedPortfolioProduct: {},
};

export const mutations = {
    SET_PORTFOLIOS(state, value) {
        state.portfolios = value
    },

    SET_PORTFOLIO(state, value) {
        state.selectedPortfolio = value
    },

    DELETE_PRODUCT_FROM_PORTFOLIO(state, value) {
        let index = state.portfolioProducts.findIndex((res) => res.incomeStreamProductId == value)
        state.portfolioProducts.splice(index, 1)
    },
 
    SET_PORTFOLIO_PRODUCTS(state, value) {
        state.portfolioProducts = value
    },
    
    SET_PORTFOLIO_PRODUCT(state, value) {
        state.selectedPortfolioProduct = value
    },
};

export const actions = {

    async findPortfolios({ commit }, userId) {
        try {
            let response = await StoreService.findAllPortfolios(userId)
            commit('SET_PORTFOLIOS', response)
            return response
        }
        catch(error) {
            console.log('find all portfolio streams action', error)
            throw error
        }
    },

    async findPortfolio({ commit }, payload) {
        try {
            const { streamId, userId } = payload;
            let response = await StoreService.findOnePortfolio(streamId, userId)
            //console.log('payload', payload)
            commit('SET_PORTFOLIO', response[0])
            commit('SET_PORTFOLIO_PRODUCTS', response[0].products)
            return response
        }
        catch(error) {
            //console.log('find one platform action', error)
        }
    },

    async findPortfolioProduct({ commit }, payload) {
        try {
            const { portfolioId, userId } = payload;
            let response = await StoreService.findOnePortfolioProduct(portfolioId, userId)
            //console.log('payload', payload)
            commit('SET_PORTFOLIO_PRODUCT', response[0])
            return response
        }
        catch(error) {
            //console.log('find one platform action', error)
        }
    },

    async deleteProductFromPortfolio({commit}, payload) {
        const { portfolioId, productId } = payload;
        try {
            //console.log('delete payload', payload)
            let response = await StoreService.deleteProductFromPortfolio(portfolioId, productId)
            commit('DELETE_PRODUCT_FROM_PORTFOLIO', productId)
            return response
        }
        catch(error) {
            //console.log('delete portfolio product action', error)
        }
    },
};

export const getters = {
    
};
