import { createApp } from 'vue';
import App from './App.vue';
import router from "./router";
import 'aos/dist/aos.css';
import i18n from './i18n';
import store from "./state/store";

import BootstrapVue3 from 'bootstrap-vue-3';
import vClickOutside from "click-outside-vue3";
import VueApexCharts from "vue3-apexcharts";
import Maska from 'maska';

import VueFeather from 'vue-feather';
import Vue3Lottie from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'

import Particles from "particles.vue3";
import TokenMixin from './mixins/TokenMixin.js';
import ValidationMixin from './mixins/ValidationMixin.js';
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

import '@/assets/scss/config/material/app.scss';
import '@vueform/slider/themes/default.css';
import '@/assets/scss/mermaid.min.css';

import VueTelInput from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'

import vue3StarRatings from "vue3-star-ratings"; 

import VideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'

// Configure the environment variables
const rootUrl = process.env.VUE_APP_ROOT;

const app = createApp(App);

app
  .use(store)
  .use(router)
  .use(VueApexCharts)
  .use(BootstrapVue3)
  .use(Vue3Lottie)
  .use(Particles)
  .use(Toast)
  .use(TokenMixin)
  .use(ValidationMixin)
  .use(VideoPlayer)
  .component(VueFeather.type, VueFeather)
  .use(Maska)
  .use(i18n)
  .use(vClickOutside)
  .component("vue3-star-ratings", vue3StarRatings);

// Register the service worker
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/service-worker.js')
    .then(function (registration) {
      //console.log('Service Worker registered with scope:', registration.scope);
    }).catch(function (error) {
      //console.log('Service Worker registration failed:', error);
    });
}

// Set the root value as a global property
app.config.globalProperties.$rootUrl = rootUrl;

// Mount the app to the DOM
app.mount('#app');
