export default {
    data() {
      return {
        tokenExpired: false
      }
    },
    methods: {
      async checkTokenExpiration() {
        this.tokenExpired = await this.isTokenExpired()
        if(this.tokenExpired) {
          //console.log("Token has expired")
            localStorage.clear()
            location.reload()
        }
      }
    },
    async mounted() {
      await this.checkTokenExpiration()
    }
}
  