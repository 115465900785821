import addressService from '../../services/address.service'

export const state = {
    addresses: [],
    selectedAddress: {}
};

export const mutations = {
    ADD_ADDRESS(state, value) {
        state.addresses.forEach(address => {
            address.default = false
        })
        state.addresses.push(value)
    },

    UPDATE_ADDRESS(state, value) {
        state.addresses.forEach(address => {
            address.default = false
        })
        let index = state.addresses.findIndex(address => address.id == value.id)
        value.default = true
        state.addresses[index] = value
    },

    SET_ADDRESSES(state, value) {
        state.addresses = value
    },

    SET_ONE_ADDRESS(state, value) {
        state.selectedAddress = value
    },

    DELETE_ADDRESS(state, value) {
        const address = value.address
        let index = state.addresses.findIndex((res) => res.id == address.id)
        state.addresses.splice(index, 1)
    },
};

export const actions = {
    
    async createAddress({commit}, payload) {
        try {
            let response = await addressService.create(payload)
            commit('ADD_ADDRESS', response[0])
            return response
        }
        catch(error) {
            //console.log('create address action', error)
        }
    },
    
    async updateAddress({commit}, payload) {
        try {
            payload.default = payload.setDefault || payload.default
            let response = await addressService.update(payload)
            commit('UPDATE_ADDRESS', response[0])
            return response
        }
        catch(error) {
            //console.log('update platform action', error)
        }
    },

    async findAllAddresses({ commit }, userId) {
        try {
            let response = await addressService.findAll(userId)
            commit('SET_ADDRESSES', response)
            return response
        }
        catch(error) {
            console.log('find all address action', error)
            throw error
        }
    },

    async findOneAddress({ commit }, id) {
        try {
            let response = await addressService.findOne(id)
            commit('SET_ONE_ADDRESS', response)
            return response
        }
        catch(error) {
            //console.log('find one address action', error)
        }
    },

    async deleteAddress({commit}, payload) {
        try {
            let response = await addressService.deleteOne(payload)
            if(response.status == 'success') {
              commit('DELETE_ADDRESS', payload)  
            }
            return response
        }
        catch(error) {
            //console.log('delete address action', error)
        }
    },
};

export const getters = {
    
};
