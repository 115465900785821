import VideoService from '../../services/product-video.service'

export const state = {
    videos: [],
    selectedVideo: {}
};

export const mutations = {
    ADD_VIDEO(state, value) {
        state.videos.push(value)
    },

    UPDATE_VIDEO(state, value) {
        let index = state.videos.findIndex(video => video.id == value.id)
        state.videos[index] = value
    },

    SET_VIDEOS(state, value) {
        state.videos = value
    },

    SET_VIDEO(state, value) {
        state.selectedVideo = value
    },

    DELETE_VIDEO(state, value) {
        let index = state.videos.findIndex((res) => res.id == value)
        state.videos.splice(index, 1)
    },
};

export const actions = {
    
    async createVideo({commit}, payload) {
        try {
            let response = await VideoService.create(payload)
            //console.log('respnse for add video', response)
            commit('ADD_VIDEO', response[0])
            return response
        }
        catch(error) {
            //console.log('create video action', error)
        }
    },

    async updateVideo({commit}, payload) {
        try {
            let response = await VideoService.update(payload)
            //console.log('respnse for update video', response)
            commit('UPDATE_VIDEO', response[0])
            return response
        }
        catch(error) {
            //console.log('update video action', error)
        }
    },

    async findAllVideos({ commit }, streamId) {
        try {
            let response = await VideoService.findAll(streamId)
            commit('SET_VIDEOS', response)
            return response
        }
        catch(error) {
            console.log('find all videos action', error)
            throw error
        }
    },

    async findVideo({ commit }, videoId) {
        try {
            let response = await VideoService.findOne(videoId)
            //console.log('payload', payload)
            commit('SET_VIDEO', response[0])
            return response
        }
        catch(error) {
            //console.log('find one video action', error)
        }
    },

    async deleteVideo({commit}, videoId) {
        try {
            let response = await VideoService.delete(videoId)
            commit('DELETE_VIDEO', videoId)
            return response
        }
        catch(error) {
            //console.log('delete portfolio stream action', error)
        }
    },
};

export const getters = {
    
};
