import http from "../common/http";

class PortfolioService {
  addToPortfolio(data) {
    return http.request('post', '/portfolio/income/add', data);
  }

  findOnePortfolio(userId, businessId) {
    return http.request('get', `/portfolio/find-one?userId=${userId}&businessId=${businessId}`);
  }

  findAllProducts(userId) {
    return http.request('get', `/portfolio/product/find-all?userId=${userId}`);
  }

  findAllPortfolios(userId) {
    return http.request('get', `/portfolio/find-all?userId=${userId}`);
  }

  findAllPortfolioBusinesses(userId) {
    return http.request('get', `/portfolio/business/find-all?userId=${userId}`);
  }

  findOnePortfolioBusiness(businessId, userId) {
    return http.request('get', `/portfolio/business/find-one?businessId=${businessId}&userId=${userId}`);
  }
  
  findPortfoliosByBusinessId(businessId) {
    return http.request('get', `/portfolio/business/find-by-id?businessId=${businessId}`);
  }

  deleteFromPortfolio(businessId, userId) {
    return http.request('delete', `/portfolio/business/delete?businessId=${businessId}&userId=${userId}`);
  }

  addProductToPortfolio(data) {
    return http.request('post', '/portfolio/product/add', data);
  }

  deleteProductFromPortfolio(portfolioId, productId) {
    return http.request('delete', `/portfolio/product/delete?portfolioId=${portfolioId}&productId=${productId}`);
  }

  findAllTransactions(userId) {
    return http.request('get', `/portfolio/transactions/find-all?userId=${userId}`);
  }

  addRecipient(data) {
    return http.request('post', '/portfolio/recipient/add', data);
  }
}

export default new PortfolioService();